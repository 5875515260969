import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { NotificationMatrix, NotificationMatrixList } from './notification-matrix.model';
import { LocalStorageService } from '../localstorage/local-storage.service';
import { Pagination } from 'app/core/api/pagination.type';

@Injectable({
    providedIn: 'root'
})
export class NotificationMatrixService {

    private _notificationMatrixes: BehaviorSubject<NotificationMatrix[] | null> = new BehaviorSubject(null);
    errorMessage: string = "Something went wrong ";
    pagination: Pagination;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,
        private _localStorage: LocalStorageService) {
    }

    get notificationMatrixes$(): Observable<NotificationMatrix[]> {
        return this._notificationMatrixes.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    getNotificationMatrixesInitial(projectId): Observable<NotificationMatrixList> {
        return this.getNotificationMatrixes(projectId, null, null, null, null, null)
    }
    getNotificationMatrixes(projectId, page: number = 0, batch_size: number = 10, sort: string = 'name', sortDirection: 'asc' | 'desc' | '' = 'asc', query: string): Observable<NotificationMatrixList> {
        if (sortDirection == 'desc')
            sort = '-' + sort;
        return this._httpClient.get<NotificationMatrixList>(`${BASE_URL}project/${projectId}/notification/`, {
            params: {
                page: page ? ++page : 1,
                ordering: sort ? sort : '',
                search: query ? query : '',
                page_size: batch_size ? batch_size : 10
            }
        }).pipe(
            tap((response) => {
                this.pagination = {
                    page: --response.page,
                    total_count: response.total_count
                };
                if (response.data.length == 0)
                    this.errorMessage = "There are no notification matrixes to display!"
                this._notificationMatrixes.next(response.data);
            })
        );
    }

    deleteNotificationMatrix(projectId, matrixId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}project/${projectId}/notification/${matrixId}/`).pipe(
            tap((res: any) => {
                this.pagination.total_count -= 1
                this._notificationMatrixes.next(this._notificationMatrixes.value.filter(matrix => matrix.id !== matrixId));
            }, err => of([]))
        )
    }

    createNotificationMatrix(projectId, credentials): Observable<any> {
        return this._httpClient.post(`${BASE_URL}project/${projectId}/notification/`, credentials);
    }

    updateNotificationMatrix(projectId, credentials, matrixId: number): Observable<any> {
        return this._httpClient.put(`${BASE_URL}project/${projectId}/notification/${matrixId}/`, credentials)
    }



}
